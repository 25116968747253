<template>
    <div>
        <div class="bg-gray-50">
            <Html class="h-full bg-white">

            <Head>
                <Title>WeTix </Title>
            </Head>

            </Html>

            <Body class="h-full"></Body>


            <LayoutHeaderConsole app-name="Communication" :top-links="topLinks" :sub-links="subLinks" :show-upgrade="false"
                :show-search="true" />

            <div class="">
                <NuxtPage />
            </div>

        </div>

        <LayoutFooterAppGeneral />

        <!-- OVERLAYS / OUT OF VIEW -->
        <client-only>
            <SearchOverlay></SearchOverlay>
        </client-only>
        <NotificationBasic></NotificationBasic>
    </div>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import {
    BellIcon,
    MenuAlt1Icon,
    HomeIcon,
    TicketIcon,
    UserIcon,
    CogIcon,
    InformationCircleIcon,
    ClipboardListIcon,
    CollectionIcon,
    SaveIcon,
    DotsVerticalIcon
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";

// Import stores
import { useSearchStore } from "~/stores/search";
import { useNotificationState } from "~/stores/notifications";

// Import useActiveElement from vueUse to activate the global search dialog when search box is clicked
// Use onKeyStroke to launch the command box when ctrl + / is pressed.
import { useActiveElement, useMagicKeys } from '@vueuse/core'

// // All admin pages should be passed through the authCheck middleware.
// definePageMeta({
//   // Use the auth middleware to check where the user should be redirected to after logging in.
//   // middleware: ["authadminonly"]
// })

// Composable to watch ID token and set cookie.
useIDTokenChange()

// Nuxt app context
const nuxtApp = useNuxtApp()

// Pinia search store
const searchStore = useSearchStore()

// Pinia notification store
const notificationStore = useNotificationState()

// Config
const config = useRuntimeConfig()

// Detect if the search box is clicked and launch the global search dialog if so.
const activeElement = useActiveElement()
watch(activeElement, (el) => {
    if (el.id === 'wetix-global-search-input') {
        console.log(`tenant-admin layout - Launching global search input`)
        searchStore.openSearchOverlay()
    }
    // else {
    //   console.log(`Focus changed to: ${el.id}`)
    // }
})

// Listen for ctrl + / keystroke to launch the command box
const keys = useMagicKeys()
const ctrlForwardslash = keys['Ctrl+K']
watch(ctrlForwardslash, (v) => {
    if (v)
        console.log('Ctrl + / have been pressed. ')
    if (v && searchStore.showGlobalSearchOverlay) {
        console.log('Closing global search box')
        searchStore.closeSearchOverlay()
    } else if (v && !searchStore.showGlobalSearchOverlay) {
        console.log('Opening global search box')
        searchStore.openSearchOverlay()
    }
})

// Links shown in the top bar
const topLinks = [
    {
        name: 'Tickets',
        to: '/app/tenant-admin'
    },
    {
        name: 'Comminicate',
        to: '/app/communication'
    },
    {
        name: 'Promote',
        to: '/app/promote'
    }
]

// Links shown in sub menu
const subLinks = [
    {
        name: 'Campaigns',
        to: '/app/communication/campaigns'
    },
    {
        name: 'Audiences',
        to: '/app/communication/audiences'
    },
    {
        name: 'Templates',
        to: '/app/communication/templates'
    },
    {
        name: 'Lists',
        to: '/app/communication/lists'
    },
]

// const navigation = [
//     {
//         name: "Dashboard",
//         href: "/app/tenant-admin",
//         icon: HomeIcon,
//         lineBreak: true,
//     },
//     {
//         name: "Events",
//         href: "/app/tenant-admin/events",
//         icon: ClipboardListIcon,
//     },
//     {
//         name: "Orders",
//         href: "/app/tenant-admin/orders",
//         icon: CollectionIcon,
//     },
//     {
//         name: "Customers",
//         icon: UserIcon,
//         href: "/app/tenant-admin/customers",
//         lineBreak: true,
//     },
//     {
//         name: "Coupons",
//         icon: TicketIcon,
//         href: "/app/tenant-admin/coupons",
//         lineBreak: true,
//     },
//     {
//         name: "Integrations",
//         href: "/app/tenant-admin/integrations",
//         icon: SaveIcon,
//         current: false,
//     },
//     {
//         name: "Settings",
//         href: "/app/tenant-admin/settings",
//         icon: CogIcon,
//         current: false,
//     },
//     {
//         name: "Help",
//         href: "/app/tenant-admin/help",
//         icon: InformationCircleIcon,
//         current: false,
//     },
//     {
//         name: "More",
//         href: "/app/tenant-admin/more",
//         icon: DotsVerticalIcon,
//         current: false,
//     },
// ];





</script>


<style scoped>
hr {
    border-top: 1px solid #444fb6;
}
</style>